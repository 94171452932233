import { Splide, SplideSlide } from '@splidejs/splide'

window.muirhall ||= {}

muirhall.home =
  setup: ->
    return unless document.querySelector('.splide')

    new Splide('.splide', {
      type: 'loop'
    }).mount()